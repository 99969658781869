<template lang="html">

  <section class="team-member">
    <bannerHead dataTitle="Equipo"></bannerHead>
    <breacrumbs v-if="Object.keys(store.employees).length != 0 " dataParent="Equipo" dataParentLink="/equipo"
      :dataTitle="getEmployerById(id).name + ' ' + getEmployerById(id).surnames"></breacrumbs>

    <section class="card-member">
      <div class="container" v-if="Object.keys(store.employees).length != 0 ">
        <div class="info" v-if="getEmployerById(id)">
          <p class="title-line -small">{{getEmployerById(id).name}} {{getEmployerById(id).surnames}}</p>
          <p class="subtitle uppercase">{{getEmployerById(id).department.name}}</p>
          <p class="title-blue">{{getEmployerById(id).header}}</p>
          <p>{{getEmployerById(id).description}}</p>
          <a :href="'tel:'+ getEmployerById(id).phone" class="link-phone"
            :title="getEmployerById(id).phone">{{getEmployerById(id).phone}}</a>
          <a :href="'mailto:'+ getEmployerById(id).email" class="link-mail"
            :title="getEmployerById(id).email">{{getEmployerById(id).email}}</a>
        </div>
        <div class="container-member">
          <img :src="getEmployerById(id).image.url"
            :alt="getEmployerById(id).name+ ' '+ getEmployerById(id).surnames" />
        </div>
      </div>
    </section>
    <div class="container-blue" v-if="Object.keys(store.employees).length != 0 ">
      <div class="container-recomended" >
        <button @click="$refs.carousel.goToPrev()" class="prevSlide" title="Next Slide"></button>
        <button @click="$refs.carousel.goToNext()" class="nextSlide" title="Prev Slide"></button>
        <div class="container">
          <agile :options="myOptions" ref="carousel" class="d-flex jcsb aife container">
            <template v-for="(employe, key ) in store.employees" :key="key">
              <router-link :to="{
              name:'team-member',
              params: {
                id: employe.id
              }}" class="link-box-img">
                <img :src="employe.image.url" :alt="employe.name+ ' '+ employe.surnames">
                <strong>{{employe.name+ ' '+ employe.surnames}} </strong>
              </router-link>
            </template>
          </agile>
        </div>
      </div>
    </div>

  </section>

</template>

<script lang="js">
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  import { contentStore } from '@/stores/contents'
  const store = contentStore();
  import {
    VueAgile
  } from "vue-agile";
  export default {
    name: 'team-member',
    props: ['id'],
    components: {
      agile: VueAgile,
      bannerHead,
      breacrumbs
    },
    setup() {
      return {
        getEmployerById: store.getEmployerById,
        store,
      }
    },
    mounted() {
      store.loadEmployees()
    },
    data() {
      return {
        myOptions: {
          responsive: [{
            breakpoint: 1400,
            settings: {
              slidesToShow: 5,
              dots: false,
              navButtons: false,
              centerMode: true,
            },
          }, {
            breakpoint: 800,
            settings: {
              slidesToShow: 3,
              dots: false,
              navButtons: false,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 2,
              dots: false,
              navButtons: false,
            },
          }, {
            breakpoint: 0,
            settings: {
              dots: false,
              slidesToShow: 1,
              navButtons: false,
            },
          },
          ],
        },
      }
    }

  }


</script>

<style scoped lang="scss">

</style>